import { render, staticRenderFns } from "./resetPasswordModal.vue?vue&type=template&id=729fc571&"
import script from "./resetPasswordModal.vue?vue&type=script&lang=js&"
export * from "./resetPasswordModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports